import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy } from 'firebase/firestore';

// Firebase configuration (replace with your own Firebase project config)
const firebaseConfig = {
  apiKey: "AIzaSyAHEIYgwlEr2W_MLmb-U6JU8C_fBSsQGHg",
  authDomain: "one-line-35229.firebaseapp.com",
  projectId: "one-line-35229",
  storageBucket: "one-line-35229.firebasestorage.app",
  messagingSenderId: "722236844429",
  appId: "1:722236844429:web:085f2fe5723e7e658535c4",
  measurementId: "G-29FGDWE4KC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const colors = [
  '#000000', // Black
  '#FF0000', // Red
  '#0000FF', // Blue
  '#00FF00', // Green
  '#FFA500', // Orange
  '#800080',  // Purple
  '#f0f4f8'
];

const OneLine = () => {
  const [currentColor, setCurrentColor] = useState('#000000');
  const [isDrawing, setIsDrawing] = useState(false);
  const [lines, setLines] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [canDraw, setCanDraw] = useState(true);
  const [attemptedSecondLine, setAttemptedSecondLine] = useState(false);
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const colorPaletteRef = useRef(null);

  // Check drawing permission on component mount
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    
    // Set canvas to full window size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  
    setCtx(context);

    // Check cookie for last draw time
    const lastDrawTime = Cookies.get('lastDrawTime');
    if (lastDrawTime) {
      const timeSinceLastDraw = Date.now() - parseInt(lastDrawTime);
      if (timeSinceLastDraw < 3600000) { // 1 hour = 3600000 ms
        setCanDraw(false);
        startCountdown(3600000 - timeSinceLastDraw);
      }
    }

    // Fetch existing lines from Firestore
    const linesRef = collection(db, 'lines');
    const q = query(linesRef, orderBy('timestamp'));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedLines = querySnapshot.docs.map(doc => doc.data());
      setLines(fetchedLines);
      
      // Redraw all lines
      context.clearRect(0, 0, canvas.width, canvas.height);
      fetchedLines.forEach(line => {
        drawLine(context, line.startX, line.startY, line.endX, line.endY, line.color);
      });
    });

    return () => unsubscribe();
  }, []);

  // Cooldown toast effect
  useEffect(() => {
    if (attemptedSecondLine) {
      const timer = setTimeout(() => {
        setAttemptedSecondLine(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [attemptedSecondLine]);

  const startCountdown = (remainingTime) => {
    setTimeRemaining(Math.ceil(remainingTime / 1000));
    
    const countdownInterval = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          setCanDraw(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const drawLine = (context, startX, startY, endX, endY, color) => {
    context.beginPath();
    context.strokeStyle = color;
    context.lineWidth = color === '#f0f4f8' ? 8 : 2;
    context.moveTo(startX, startY);
    context.lineTo(endX, endY);
    context.stroke();
    context.closePath();
  };

  const showCooldownFeedback = () => {
    setAttemptedSecondLine(true);
    
    // Optional: Add shake animation to canvas
    const canvas = canvasRef.current;
    canvas.classList.add('animate-shake');
    setTimeout(() => {
      canvas.classList.remove('animate-shake');
    }, 500);
  };

  const startDrawing = (e) => {
    if (!canDraw) {
      showCooldownFeedback();
      return;
    }

    setIsDrawing(true);
    const { offsetX, offsetY } = e.nativeEvent;
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
  };

  const draw = (e) => {
    if (!isDrawing || !canDraw) {
      if (!canDraw) showCooldownFeedback();
      return;
    }
    
    const { offsetX, offsetY } = e.nativeEvent;
    const lastX = ctx.canvas.lastX || offsetX;
    const lastY = ctx.canvas.lastY || offsetY;

    // Save line to Firestore
    const lineData = {
      startX: lastX,
      startY: lastY,
      endX: offsetX,
      endY: offsetY,
      color: currentColor,
      timestamp: new Date()
    };

    addDoc(collection(db, 'lines'), lineData);

    // Update canvas state
    ctx.canvas.lastX = offsetX;
    ctx.canvas.lastY = offsetY;
  };

  const stopDrawing = () => {
    if (!isDrawing || !canDraw) {
      if (!canDraw) showCooldownFeedback();
      return;
    }

    setIsDrawing(false);
    ctx.canvas.lastX = undefined;
    ctx.canvas.lastY = undefined;
    ctx.beginPath();

    // Set cookie to track last draw time
    Cookies.set('lastDrawTime', Date.now().toString(), { expires: 1/24 }); // Expires in 1 hour
    setCanDraw(false);
    startCountdown(3600000); // 1 hour countdown
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#F0F4F8] relative overflow-hidden">
      {/* Header */}
      <div className="absolute top-0 left-0 right-0 bg-[#1A365D] text-white p-4 shadow-md z-40">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">One Line</h1>
          <p className="text-sm text-gray-300 max-w-md text-right">
            A collaborative drawing experience where you can draw one line per hour. 
            Creativity is a limited resource - use it wisely!
          </p>
        </div>
      </div>

      {/* Cooldown Toast */}
      {attemptedSecondLine && (
        <div 
          className="absolute top-20 z-50 bg-red-500 text-white p-3 rounded-lg shadow-lg 
                     animate-bounce transform transition-all duration-300 ease-in-out"
        >
          You can only draw one line per hour!
        </div>
      )}

      {/* Countdown Timer */}
      {!canDraw && (
        <div className="absolute top-20 z-20 bg-blue-600 text-white p-2 rounded shadow-md">
          Next draw available in: {formatTime(timeRemaining)}
        </div>
      )}

      {/* Color Palette - Floating Above Canvas */}
      <div 
        ref={colorPaletteRef}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 z-50 
                   flex flex-col space-y-2 bg-white/80 backdrop-blur-sm p-3 rounded-full shadow-md"
      >
        {colors.map((color) => (
          <button
            key={color}
            className="w-10 h-10 rounded-full transition-all duration-200 
                       hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2
                       hover:shadow-lg"
            style={{ 
              backgroundColor: color,
              opacity: canDraw ? 1 : 0.5,
              cursor: canDraw ? 'pointer' : 'not-allowed'
            }}
            
            
            // Ensure this doesn't trigger canvas drawing events
            onMouseDown={(e) => {
              e.stopPropagation(); // Prevent event from reaching canvas
              e.preventDefault(); // Prevent default behavior
              if (canDraw) {
                setCurrentColor(color);
              }
            }}
            disabled={!canDraw}
          />
        ))}
      </div>

      {/* Canvas with shake animation */}
      <style jsx>{`
        @keyframes shake {
          0%, 100% { transform: translateX(0); }
          10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
          20%, 40%, 60%, 80% { transform: translateX(5px); }
        }
        .animate-shake {
          animation: shake 0.5s;
        }
      `}</style>
      <canvas
        ref={canvasRef}
        className="absolute top-0 left-0 z-10"
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        style={{ 
          cursor: canDraw ? 'crosshair' : 'not-allowed',
          pointerEvents: canDraw ? 'auto' : 'none'
        }}
      />
    </div>
  );
};

export default OneLine;